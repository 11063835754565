<template>
  <div class="content-dashboard">
    <div class="content-global">
      <div ref="searchDashboard">
        <header-component-global
          sTextTitle="Dashboard"
          sTextBtnAdd=""
          :bAdminPermissions="false"
          setDialog=""
          @setRangeDate="setRangeDate"
        />
      </div>
      <div class="content-info-filter-result">
        <p class="text-info-filter-result">
          {{ sDashboardMessageFilter }}
        </p>
      </div>
      <amounts-component
        class="mb-5"
        :aAmounts="aAmounts"
        :aAmountsUSD="aAmountsUSD"
      />
      <graphics-component
        class="mb-5"
        :aGraphic="aGraphic"
        :bResizeGraphics="bResizeGraphics"
      />
      <rankings-component class="mb-2" :aRankins="aRankins" />
    </div>
    <!-- <footer-component-global /> -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      screenHeight: 0,
      heightMax: 0,
      sApiGetOC: "",
      oDateRange: {},
      oItemDashboard: {},
      aAmounts: [],
      aAmountsUSD: [],
      aGraphic: [],
      aGraphicUSD: [],
      aRankins: [],
      sDashboardMessageFilter: "",
      bResizeGraphics: false,
    };
  },
  beforeMount() {
    this.getDashboard();
  },
  updated() {
    this.matchHeight();
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
    window.addEventListener("resize ", this.matchHeight);
    this.matchHeight();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
    window.addEventListener("resize ", this.matchHeight);
  },
  methods: {
    //#region RESIZE PAGE
    matchHeight() {
      this.$nextTick((e) => {
        if (this.$refs.searchDashboard !== undefined) {
          this.heightMax = this.$refs.searchDashboard.clientHeight;
          this.heightMax = this.heightMax + 143;
        }
        if (window.innerWidth > 600) {
          this.screenHeight = window.innerHeight - 100;
        } else {
          this.screenHeight = window.innerHeight - 100;
        }
      });
    },
    handleResize: function () {
      this.matchHeight();
    },
    //#endregion RESIZE PAGE
    getDashboard() {
      this.$store
        .dispatch("getPermissionsByUserGlobal")
        .then((resp) => {
          DB.get(`${URI}/api/sp/v1/stats`, {
            params: {
              tStart: this.oDateRange.tStart,
              tEnd: this.oDateRange.tEnd,
            },
            headers: {
              Authorization: "Bearer " + this.$store.state.sToken,
            },
          })
            .then((response) => {
              this.oItemDashboard = response.data;
              this.sDashboardMessageFilter =
                this.oItemDashboard.sDashboardMessage;
              this.aAmounts = [];
              this.aAmounts.push(
                {
                  sTitle: "Balance global",
                  sColorBtn: "btn-currency-color-yellow",
                  sColorText: "text-currency-color-yellow",
                  iAmount:
                    this.oItemDashboard.totalBalance.dMexicanPesoBalance
                      .dAmount,
                },
                {
                  sTitle: "Ingresos periodo actual",
                  sColorBtn: "btn-currency-color-green",
                  sColorText: "text-currency-color-green",
                  iAmount:
                    this.oItemDashboard.incomeBalance.dMexicanPesoBalance
                      .dAmount,
                },
                {
                  sTitle: "Egreso periodo actual",
                  sColorBtn: "btn-currency-color-red",
                  sColorText: "text-currency-color-red",
                  iAmount:
                    this.oItemDashboard.expensesBalance.dMexicanPesoBalance
                      .dAmount,
                }
              );

              this.aAmountsUSD = [];
              this.aAmountsUSD.push(
                {
                  sTitle: "Balance global",
                  sColorBtn: "btn-currency-color-yellow",
                  sColorText: "text-currency-color-yellow",
                  iAmount:
                    this.oItemDashboard.totalBalance.oUSDollarBalance.dAmount,
                },
                {
                  sTitle: "Ingresos periodo actual",
                  sColorBtn: "btn-currency-color-green",
                  sColorText: "text-currency-color-green",
                  iAmount:
                    this.oItemDashboard.incomeBalance.oUSDollarBalance.dAmount,
                },
                {
                  sTitle: "Egreso periodo actual",
                  sColorBtn: "btn-currency-color-red",
                  sColorText: "text-currency-color-red",
                  iAmount:
                    this.oItemDashboard.expensesBalance.oUSDollarBalance
                      .dAmount,
                }
              );
              

              // #region aCategoriesIncomeAndExpenses
              let aCategoriesIncomeAndExpenses =
                this.oItemDashboard.graphIncomeAndExpensesMXN.map((e) => {
                  return e.tName;
                });

              let aIncome = this.oItemDashboard.graphIncomeAndExpensesMXN.map(
                (e) => {
                  return e.tIncome;
                }
              );

              let aExpenses = this.oItemDashboard.graphIncomeAndExpensesMXN.map(
                (e) => {
                  return e.tExpense;
                }
              );
              // #endregion aCategoriesIncomeAndExpenses

              // #region aCategoriesIncomeAndExpensesUSD
              let aCategoriesIncomeAndExpensesUSD =
                this.oItemDashboard.graphIncomeAndExpensesUSD.map((e) => {
                  return e.tName;
                });

              let aIncomeUSD = this.oItemDashboard.graphIncomeAndExpensesUSD.map(
                (e) => {
                  return e.tIncome;
                }
              );

              let aExpensesUSD = this.oItemDashboard.graphIncomeAndExpensesUSD.map(
                (e) => {
                  return e.tExpense;
                }
              );
              // #endregion aCategoriesIncomeAndExpensesUSD

              // #region graphRawMaterialInventory
              let aCategoriesRawMaterial =
                this.oItemDashboard.graphRawMaterialInventory.map((e) => {
                  return e.sName;
                });
              let aEntryRawMaterial =
                this.oItemDashboard.graphRawMaterialInventory.map((e) => {
                  return e.dAmountEntry;
                });
              let aExitRawMaterial =
                this.oItemDashboard.graphRawMaterialInventory.map((e) => {
                  return e.dAmountExit;
                });
              // #endregion graphRawMaterialInventory


              this.aGraphic = [
                {
                  sIdGraphic: 1,
                  sTitle: "Entradas y salidas de materias primas",
                  sNameTextBottom: "Materias Primas",
                  aFilterMenu: [
                    {
                      sText: "Entradas",
                      sValue: "entradas",
                    },
                    {
                      sText: "Salidas",
                      sValue: "salidas",
                    },
                  ],
                  sTypeChart: "bar",
                  series: [
                    {
                      name: "Entradas",
                      data: aEntryRawMaterial,
                      colors: "#56ADE8",
                    },
                    {
                      name: "Salidas",
                      data: aExitRawMaterial,
                      colors: "#56E7B7",
                    },
                  ],
                  chartOptions: {
                    colors: ["#56ADE8", "#56E7B7"],

                    chart: {
                      width: 400,
                      type: "area",
                      zoom: {
                        enabled: false,
                        type: "x",
                        autoScaleYaxis: false,
                        zoomedArea: {
                          fill: {
                            color: "#90CAF9",
                            opacity: 0.4,
                          },
                          stroke: {
                            color: "#0D47A1",
                            opacity: 0.4,
                            width: 100,
                          },
                        },
                      },
                    },
                    dataLabels: {
                      enabled: false,
                    },
                    stroke: {
                      curve: "smooth",
                    },
                    legend: {
                      position: "top",
                      horizontalAlign: "right",
                    },
                    xaxis: {
                      // title: {
                      //     text: 'Materia prima',
                      //     rotate: -90,
                      //     style: {
                      //         cssClass: 'apexcharts-xaxis-title'
                      //     }
                      // },
                      type: "int",
                      categories: aCategoriesRawMaterial,
                    },
                    yaxis: {
                      labels: {
                        formatter: (value) => {
                          return value + "Kg";
                        },
                      },
                    },
                    tooltip: {
                      y: {
                        formatter: function (y) {
                          if (typeof y !== "undefined") {
                            return y.toFixed(2) + "Kg";
                          }
                          return y;
                        },
                        format: "k",
                      },
                      z: {
                        formatter: undefined,
                      },
                    },
                  },
                },
                {
                  sIdGraphic: 2,
                  sTitle: "Variación entre igresos y egresos(MXN)",
                  sNameTextBottom: "Días",
                  sNameTextLeft: "Monto",
                  aFilterMenu: [
                    {
                      sText: "Ingresos",
                      sValue: "ingresos",
                    },
                    {
                      sText: "Egresos",
                      sValue: "engresos",
                    },
                  ],
                  sTypeChart: "area",
                  series: [
                    {
                      name: "Ingresos",
                      data: aIncome,
                      colors: "#42B561",
                    },
                    {
                      name: "Egresos",
                      data: aExpenses,
                      colors: "#CE3131",
                    },
                  ],
                  chartOptions: {
                    colors: ["#42B561", "#CE3131"],
                    chart: {
                      width: 400,
                      type: "area",
                      zoom: {
                        enabled: false,
                        type: "x",
                        autoScaleYaxis: false,
                      },
                    },
                    dataLabels: {
                      enabled: false,
                    },
                    stroke: {
                      curve: "smooth",
                      colors: ["#42B561", "#CE3131"],
                    },
                    fil: {
                      colors: ["#42B561", "#CE3131"],
                    },
                    legend: {
                      position: "top",
                      horizontalAlign: "right",
                    },
                    xaxis: {
                      // title: {
                      //     text: 'Días',
                      //     style: {
                      //         cssClass: 'apexcharts-xaxis-title'
                      //     }
                      // },
                      type: "int",
                      categories: aCategoriesIncomeAndExpenses,
                    },
                    yaxis: {
                      // title: {
                      //     text: 'Monto',
                      //     style: {
                      //         cssClass: 'apexcharts-yaxis-title'
                      //     }

                      // },
                      labels: {
                        formatter: (value) => {
                          return "$" + value + " MXN";
                        },
                      },
                    },
                    tooltip: {
                      y: {
                        formatter: function (y) {
                          if (typeof y !== "undefined") {
                            return "$" + y.toFixed(2) + " MXN";
                          }
                          return y;
                        },
                        format: " MXN",
                      },
                      z: {
                        formatter: undefined,
                      },
                    },
                  },
                },
                {
                  sIdGraphic: 3,
                  sTitle: "Variación entre igresos y egresos(USD)",
                  sNameTextBottom: "Días",
                  sNameTextLeft: "Monto",
                  aFilterMenu: [
                    {
                      sText: "Ingresos",
                      sValue: "ingresos",
                    },
                    {
                      sText: "Egresos",
                      sValue: "engresos",
                    },
                  ],
                  sTypeChart: "area",
                  series: [
                    {
                      name: "Ingresos",
                      data: aIncomeUSD,
                      colors: "#42B561",
                    },
                    {
                      name: "Egresos",
                      data: aExpensesUSD,
                      colors: "#CE3131",
                    },
                  ],
                  chartOptions: {
                    colors: ["#42B561", "#CE3131"],
                    chart: {
                      width: 400,
                      type: "area",
                      zoom: {
                        enabled: false,
                        type: "x",
                        autoScaleYaxis: false,
                      },
                    },
                    dataLabels: {
                      enabled: false,
                    },
                    stroke: {
                      curve: "smooth",
                      colors: ["#42B561", "#CE3131"],
                    },
                    fil: {
                      colors: ["#42B561", "#CE3131"],
                    },
                    legend: {
                      position: "top",
                      horizontalAlign: "right",
                    },
                    xaxis: {
                      // title: {
                      //     text: 'Días',
                      //     style: {
                      //         cssClass: 'apexcharts-xaxis-title'
                      //     }
                      // },
                      type: "int",
                      categories: aCategoriesIncomeAndExpensesUSD,
                    },
                    yaxis: {
                      // title: {
                      //     text: 'Monto',
                      //     style: {
                      //         cssClass: 'apexcharts-yaxis-title'
                      //     }

                      // },
                      labels: {
                        formatter: (value) => {
                          return "$" + value + " USD";
                        },
                      },
                    },
                    tooltip: {
                      y: {
                        formatter: function (y) {
                          if (typeof y !== "undefined") {
                            return "$" + y.toFixed(2) + " USD";
                          }
                          return y;
                        },
                        format: " USD",
                      },
                      z: {
                        formatter: undefined,
                      },
                    },
                  },
                },
              ];

              this.aRankins = [];
              this.aRankins.push(
                {
                  iIdRankins: 1,
                  sTypeData: "Number",
                  sTypeItem: "Provider",
                  sTitle: `Proveedores con  más operaciones`,
                  aRankinMXN: this.oItemDashboard.providersWithMostOperations.aMexicanPesoTopProviders,
                  aRankinUSD: this.oItemDashboard.providersWithMostOperations.aUSDollarTopProviders,
                },
                {
                  iIdRankins: 2,
                  sTypeData: "Money",
                  sTypeItem: "Provider",
                  sTitle: `Proveedores con  más impacto`,
                  aRankinMXN: this.oItemDashboard.providerWithMostOperationsMoney.aMexicanPesoProvidersOperationMoney,
                  aRankinUSD: this.oItemDashboard.providerWithMostOperationsMoney.aUSDollarProvidersOperationMoney,
                },
                {
                  iIdRankins: 3,
                  sTypeData: "Number",
                  sTypeItem: "Client",
                  sTitle: `Clientes con  operaciones`,
                  aRankinMXN: this.oItemDashboard.clientsWithMostOperations.aMexicanPesoTopClients,
                  aRankinUSD: this.oItemDashboard.clientsWithMostOperations.aUSDollarTopClients,
                },
                {
                  iIdRankins: 4,
                  sTypeData: "Money",
                  sTypeItem: "Client",
                  sTitle: `Clientes con  más impacto`,
                  aRankinMXN: this.oItemDashboard.clientsWithMostOperationsMoney.aMexicanPesoClientsOperationsMoney,
                  aRankinUSD: this.oItemDashboard.clientsWithMostOperationsMoney.aUSDollarClientsOperationsMoney,
                },
                {
                  iIdRankins: 5,
                  sTypeData: "Money",
                  sTypeItem: "Client and Provider",
                  sTitle: `Precios promedio de compra y venta`,
                  aRankinMXN: this.oItemDashboard.averagePrices.aMexicanPesoAveragePrice
                    ? this.oItemDashboard.averagePrices.aMexicanPesoAveragePrice
                    : [],
                  aRankinUSD: this.oItemDashboard.averagePrices.aUSDollarAveragePrice
                    ? this.oItemDashboard.averagePrices.aUSDollarAveragePrice
                    : [],
                }
              );
              this.bResizeGraphics = !this.bResizeGraphics;
              this.$store.commit("refresher", false);
            })
            .catch((error) => {
              this.bLoadingTable = false;
              this.Error(error.response.data);
            });
        })
        .catch((err) => {
          this.Error(err);
        });
    },
    setRangeDate(oDateRange) {
      this.oDateRange = oDateRange;
      this.getDashboard();
    },
  },
};
</script>

<style scoped>
.content-dashboard {
  /* background-color: darkslategrey; */
  padding: 0px 30px 0px 35px;
}

.text-info-filter-result {
  font-family: "pop-Regular";
  font-size: 19px;
  color: var(--primary-color-text);
}

/*#region MODO RESPONSIVO MAQUETA */

/*#region XS */
@media (max-width: 599px) {
  .demoColorResponsive {
    background-color: rgb(190, 136, 226);
  }

  .content-info-filter-result {
    display: block;
    margin-right: 10px;
    margin-left: 10px;
  }
}

/*#endregion XS */

/*#region SM */
@media (min-width: 600px) and (max-width: 959px) {
  .demoColorResponsive {
    background-color: rgb(136, 226, 151);
  }

  .content-info-filter-result {
    display: block;
    margin-right: 10px;
    margin-left: 10px;
  }
}

/*#endregion SM */

/*#region MD */
@media (min-width: 960px) and (max-width: 1264px) {
  .demoColorResponsive {
    background-color: rgb(201, 122, 83);
  }
}

/*#endregion MD */

/*#region LG */
@media (min-width: 1264px) and (max-width: 1904px) {
  .demoColorResponsive {
    background-color: rgb(204, 198, 109);
  }
}

/*#endregion LG */

/*#region XL */
@media (min-width: 1904px) {
  .demoColorResponsive {
    background-color: rgb(113, 199, 201);
  }
}

/*#endregion XL */

/*#endregion MODO RESPONSIVO  MAQUETA */
</style>
